// Dashboard Sidebar
.dashboard-sidebar {
  // background: rgba(0, 0, 0, 0);
  z-index: 99999;
  width: $pageWidth65;
  height: 100%;
  inset: 0;
  overflow: hidden;
  @include anim;

  @include media("<=tablet") {
    width: $pageWidth65;
  }

  &.full-open {
    width: $pageWidth;

    @include media("<=tablet") {
      width: 0;
    }
  }

  .side-block {
    width: $pageWidth65;
    background-color: #101010;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    border-right: 1px solid #616161;
    @include anim;

    &::-webkit-scrollbar {
      width: 5px;
    }

    @include media("<=tablet") {
      width: 0;
    }

    @include media(">=phone", "<=480px") {
      width: 70%;
    }

    &.full-open {
      left: 0;
      width: $pageWidth;

      .body {
        overflow-y: hidden;
      }

      @include media("<=tablet") {
        width: 0;
        left: -10px;
      }
    }

    .hdr {
      min-height: 78px;
      padding: 8px 10px;
      width: 100%;
      background-color: #101010;
      border-bottom: 1px solid #616161;

      &.center {
        justify-content: center;

        &:hover {
          cursor: pointer;
        }
      }

      .hdr-tag {
        min-width: max-content;
        font-weight: 700;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
      }

      .icon-close {
        height: 20px;
        cursor: pointer;

        svg {
          height: 30px;
          width: 30px;

          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }

    .menu-list {
      padding: 20px 16px;

      @include media("<=tablet") {
        // display: none;
      }

      .menu-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 29px;
        text-align: left;
        color: #ffffff;
        cursor: pointer;
        @include anim;

        &:hover {
          color: $themeColor;
        }

        .icon {}

        &.active {
          .icon {
            svg {
              path {
                fill: $themeColor;
              }
            }
          }
        }
      }
    }

    .dropdown-list-menu {
      background: #fff;
      width: 180px;
      position: absolute;
      top: 45px;
      right: 0px;
      padding: 8px 0px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
      border-radius: 4px;

      &.show {
        @include dropDownAnim;
      }

      .tag {
        color: #121212;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 12px;
      }
    }

    .sidebar-footer {
      padding: 14px;
      width: 100%;

      .action {
        .btn {
          padding: 8px 18px;
          background: #f1f1f1;
          border-radius: 30px;

          .btn-lbl {
            margin-left: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
        }
      }
    }
  }
}