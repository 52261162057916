// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 40px 0px;
  background: #101010;
  border-top: 1px solid $themeColor;
  // background: linear-gradient(358.14deg, rgba(136, 255, 243, 0.843) -0.47%, rgba(125, 249, 255, 0.8) 98.24%);

  .wrap {}
}