.fitness-app-page {
    padding: 40px 0;
    width: 100%;
    height: 100%;
    min-height: 730px;

    .hero-section {
        @include media("<=tablet") {
            min-height: 810px;
        }

        .box-shadow {
            top: -100px;
            left: -100px;
            height: 350px;
            width: 350px;
            position: absolute;
            // background: #7df9ff80;
            border-radius: 50%;
            background: rgba(125, 249, 255, 0.5);
            filter: blur(160px);
        }

        .wrap {
            .box-shadow-img {
                // top: -100px;
                // left: -100px;
                height: 300px;
                width: 300px;
                position: absolute;
                // background: #7df9ff80;
                border-radius: 50%;
                background: rgba(125, 249, 255, 0.5);
                filter: blur(140px);
            }

            .hero-slug {
                color: #ffffff;
                font-size: 30px;
                font-family: "Zen Dots", sans-serif;

                span {
                    color: #7df9ff;
                }
            }

            .sec-block {
                @include media("<=tablet") {
                    flex-direction: column;
                }

                .left {
                    @include media("<=tablet") {
                        margin-top: 100px;
                    }

                    @include media("<=phoneH") {
                        margin-top: 10px;
                    }
                }

                .right {}
            }
        }
    }
}