.alert-danger {
  @apply bg-rose-100 text-rose-700 p-3 mb-2 border-l-4 border-rose-600 text-sm font-medium;
}
.alert-success {
  @apply bg-indigo-100 text-indigo-900 p-3 mb-2 text-sm font-medium border-l-4 border-indigo-600;
}
.zen-title-w {
  @apply text-white sm:text-3xl text-xl;
  font-family: "Zen Dots", sans-serif;
}
.zen-title-b {
  @apply text-[#7DF9FF] sm:text-3xl text-xl;
  font-family: "Zen Dots", sans-serif;
}
