.btn {
  @apply py-2 px-5 text-white rounded;
}
.btn-dark {
  @apply bg-gray-900 px-4 py-3 capitalize font-medium;
}
.btn-indigo {
  @apply bg-indigo-600 text-white capitalize font-medium px-4 py-3 cursor-pointer !important;
}
.btn-warning {
  @apply bg-orange-400 py-[6px];
}
.btn-danger {
  @apply bg-rose-500 py-[6px];
}

.btn-radius {
  border-radius: 0 8px;
}
