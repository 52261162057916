@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
$pageWidth: 280px;
$pageWidth65: 85px;
@import "../components/Header/style.scss";
@import "../components/DashboardHeader/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../components/DashboardSidebar/style.scss";
@import "../Pages/Home/style.scss";
@import "../Pages/FitnessApp/style.scss";
@import "../Pages/Marketplace/style.scss";
@import "../Pages/Login/style.scss";

.page-width {
  width: $pageWidth;
}

.page-width65 {
  width: $pageWidth65;
}

.font,
body {
  font-family: "Poppins", sans-serif;
}

.zen-dots {
  font-family: "Zen Dots", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 1200px;
  margin: 0 auto;

  @include media("<=xlarge") {
    width: 1100px;
    margin: 0 auto;
  }

  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
  background: #101010;
}

//Drop Down

.dropDown {
  width: 100%;

  .category {
    width: 100%;

    .cbox {
      // background: #000403;
      border: 1px solid $themeColor;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;

      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }

      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }

      .slt {
        overflow: hidden;

        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }

        .lbl {
          color: #000;
          padding: 0 10px;
        }

        .unit-name {
          .unit-eng {
            color: #fff;
            font-family: "Zen Dots", sans-serif;
          }
        }
      }

      .d-img {
        margin: 2px 10px;

        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }

      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }

      .dropDown-icon {
        cursor: pointer;
      }
    }
  }

  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #000403;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    width: 100%;

    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;

        &.active {
          background-color: $themeColor;
          color: #242424;

          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }

        .unit-name {
          .unit-eng {
            color: #eee;
          }
        }

        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }

        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }

        &:hover {
          background-color: #1D1D1D;
          cursor: pointer;

          .ico {
            color: $themeColor;
          }

          .lbl {
            color: $themeColor;
          }
        }
      }
    }

    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Wrapper
.landing-page-layout {
  min-height: 730px;

  .wrap {}
}

// Dashboard
.dashboard-page-layout {
  height: 100%;
  min-height: 100vh;
  margin-left: $pageWidth65;
  background: #eeeeee;
  @include anim;

  @include media("<=tablet") {
    margin-left: 0;
  }

  &.full-open {
    margin-left: $pageWidth;

    @include media("<=tablet") {
      margin-left: 0;
    }
  }
}

.token-shape-image {
  .box-shadow {
    height: 150px;
    width: 150px;
    position: absolute;
    border-radius: 50%;
    background: rgba(125, 249, 255, 0.8);
    filter: blur(160px);
  }
}

.timer-comp {
  display: flex;
  align-items: center;
  justify-content: end;
}

.timer-box {
  display: flex;
  align-items: center;
  gap: 0px;
}

.timer-comp .time-item {
  min-width: max-content;
  display: flex;
  color: #ffffff;
  padding: 0px 2px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
}

.timer-comp .time-item ._vale {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7DF9FF;
  font-size: 12px;
  height: 24px;
  width: 24px;
  padding: 2px;
  border-radius: 4px;
  // background: linear-gradient(162deg, #1c0057 14.01%, #e069e4 87.66%);

  @include media("<=tablet") {
    height: 18px;
    width: 18px;
  }
}

.timer-comp .time-item ._tag {
  color: #ffffff;
  font-size: 12px;
  margin-left: 4px;

  @include media("<=phoneH") {
    font-size: 10px;
    margin-left: 2px;
  }
}


.currency-box {
  .currency-dropdown-list-menu {
    background: #000;
    width: 180px;
    position: absolute;
    top: 60px;
    right: 0px;
    left: 0;
    padding: 20px 16px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
    border-radius: 10px;
    width: 100%;
    border: 2px solid #1D1D1D;

    &.show {
      @include dropDownAnim;
    }

    .tag {
      color: #121212;
      font-size: 14px;
      font-weight: 500;
      padding: 6px 12px;
    }
  }
}

.ref-block {
  .ref-info {
    display: none;
  }

  .ref {
    &:hover {
      .ref-info {
        display: flex;
      }
    }
  }
}

.loader-cmp {
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(7px);
  background: rgba(36, 35, 35, 0.65);
  z-index: 9999999999;
  overflow: hidden;
  position: fixed;
  inset: 0;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $themeColor;
      border-color: $themeColor transparent $themeColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

.numb {
  &::-webkit-scrollbar {
    height: 2px;
  }
}



  .wrap {
    .page-block {
      .page-hdr {
        margin-bottom: 30px;
        .title {
          color: #fff;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .page-desc {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          @include media("<=phoneH") {
            text-align: center;
          }
        }
      }
      .swap-box {
        min-height: 400px;
        border-radius: 24px;
        border: 1px solid #3ae8e5;
        background: #101010;
        padding: 30px;
        .box-lbl {
          color: #d1d1d1;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
        .pay-block {
          gap: 12px;
          .pay-section {
            width: 100%;
            border-radius: 20px;
            background: #1c1c1c;
            padding: 20px;
            min-height: 100px;
            .left {
              flex: 1;
              .input-field {
                .field-lbl {
                  color: #d1d1d1;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .txt {
                  color: #898989;
                  font-size: 28px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .val {
                  color: #777;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
            .right {
            }
          }
          .arrow-down-icon {
            height: 40px;
            width: 40px;
            border-radius: 6px;
            top: 46%;
            left: 40%;
          }
        }
        .info-list {
          padding: 16px;
          gap: 12px;
          .info-item {
            width: 100%;

            .lbl {
              color: #777;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .val {
              color: #777;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .btn-connect {
          border-radius: 12px;
          background: #3a57e8;
          padding: 18px;
          margin-top: 16px;
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

